interface Route {
  en: string
  lt: string
  query?: boolean
}

export const routes: { [key: string]: Route } = {
  '/about': {
    en: '/about',
    lt: '/apie-mus'
  },
  '/features': {
    en: '/features',
    lt: '/funkcijos'
  },
  '/forgot-password': {
    en: '/forgot-password',
    lt: '/atstatyti-slaptazodi'
  },
  '/apply': {
    en: '/apply',
    lt: '/pradzia'
  },
  '/contacts': {
    en: '/contacts',
    lt: '/susisiekite'
  },
  '/employer-agreement': {
    en: '/employer-agreement',
    lt: '/darbdavio-sutartis'
  },
  '/terms-and-conditions': {
    en: '/terms-and-conditions',
    lt: '/taisyklės-ir-sąlygos'
  },
  '/reset-password': {
    en: '/reset-password',
    lt: '/nustatykite-naują-slaptažodį'
  },
  '/pricing': {
    en: '/pricing',
    lt: '/kainodara'
  },
  '/privacy-policy': {
    en: '/privacy-policy',
    lt: '/privatumo-politika'
  },
  '/offers': {
    en: '/offers',
    lt: '/akcijos'
  },
  '/subscription-rules': {
    en: '/subscription-rules',
    lt: '/prenumeratos-taisyklės'
  },
  '/community': {
    en: '/expert-insights',
    lt: '/ekspertu-izvalgos'
  },
  '/community/[id]': {
    en: '/expert-insights',
    lt: '/ekspertu-izvalgos',
    query: false
  },
  '/community/category/employers-challenges': {
    en: '/expert-insights/category/employers-challenges',
    lt: '/ekspertu-izvalgos/kategorija/darbdaviu-issukiai'
  },
  '/community/category/labour-market-insights': {
    en: '/expert-insights/category/labour-market-insights',
    lt: '/ekspertu-izvalgos/kategorija/darbo-rinkos-tendencijos'
  },
  '/community/category/tips-for-job-seekers': {
    en: '/expert-insights/category/tips-for-job-seekers',
    lt: '/ekspertu-izvalgos/kategorija/ekspertu-izvalgos-ieskantiems-darbo'
  },
  '/community/category/other': {
    en: '/expert-insights/category/other',
    lt: '/ekspertu-izvalgos/kategorija/kita'
  },
  '/ad/[id]': {
    en: '/job-ad/',
    lt: '/darbo-skelbimas/',
    query: true
  }
}
