import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import Spinner from 'components/Spinner'

import { useSaveUserSettings, useUserSettings } from 'hooks/useProfile'
import { LanguageSettingsContextProvider } from 'context/language-manager'

import { routes } from 'data/routes'
import { ILocale } from 'types/app'
import { langStorageKeyConstant } from 'constants/storage.constant'

const UserSettings = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const { mutate } = useSaveUserSettings({})
  const [portalLocation, setPortalLocation] = useState(router.locale)
  const [isLanguageChanging, setIsLanguageChanging] = useState(false)
  const [isDefiningLocation, setIsDefiningLocation] = useState(false)
  const { status } = useSession()
  const isSessionLoading = useMemo(() => status === 'loading', [status])
  const { isInitialLoading } = useUserSettings({
    options: {
      enabled: status === 'authenticated',
      onSuccess({ interfaceLanguage }) {
        setIsLanguageChanging(false)
        updateLocation((interfaceLanguage as ILocale) ?? 'en')
      }
    }
  })
  const isProcessing = useMemo(
    () => isSessionLoading || isInitialLoading || isDefiningLocation,
    [isDefiningLocation, isInitialLoading, isSessionLoading]
  )

  const updateLocation = useCallback((locale: ILocale, saveToStore = true) => {
    if (saveToStore) {
      localStorage.setItem(langStorageKeyConstant, locale)
    }

    setPortalLocation((prev) => {
      if (prev !== locale) {
        setIsLanguageChanging(true)
      }
      return locale
    })
  }, [])

  const switchLanguage = useCallback(
    (locale: ILocale) => {
      if (status == 'authenticated') {
        mutate({ interfaceLanguage: locale })
      }
      const cookiebotScript = document.getElementById('Cookiebot')
      if (cookiebotScript) {
        cookiebotScript.dataset.culture = locale
        if (window.Cookiebot && !window.Cookiebot.hasResponse) {
          window.Cookiebot.renew()
        }
      }

      updateLocation(locale)
    },
    [mutate, status, updateLocation]
  )
  useEffect(() => {
    const routeChangeComplete = () => {
      setIsLanguageChanging(false)
    }

    router.events.on('routeChangeComplete', routeChangeComplete)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', routeChangeComplete)
    }
  }, [router])

  useEffect(() => {
    if (isInitialLoading) {
      setIsLanguageChanging(true)
    }
  }, [isInitialLoading])

  useEffect(() => {
    if (!portalLocation || isProcessing || router.locale === portalLocation) {
      return
    }

    const path = routes[router.pathname]?.[portalLocation]
    const hasQuery = routes[router.pathname]?.query
    const isPath = path && path !== router.asPath && !hasQuery

    router.push(
      {
        pathname: isPath ? path : router.pathname,
        query: hasQuery ? router.query : {}
      },
      isPath ? path : router.asPath,
      {
        locale: portalLocation
      }
    )
  }, [isProcessing, portalLocation, router, switchLanguage])

  useEffect(() => {
    if (isDefiningLocation || status !== 'unauthenticated') {
      return
    }

    const locale = localStorage.getItem(langStorageKeyConstant)

    if (locale) {
      // change portal language to saved interface language
      updateLocation(locale as ILocale, false)
      return
    }

    // if locale LT skip defined locale
    if (router.locale === 'lt') {
      updateLocation(router.locale)
      return
    }

    // try to define language if we don't have
    setIsDefiningLocation(true)
    fetch('https://www.cloudflare.com/cdn-cgi/trace')
      .then((response) => response.text())
      .then((two) => {
        let data = two.replace(/[\r\n]+/g, '","').replace(/=+/g, '":"')
        data = '{"' + data.slice(0, data.lastIndexOf('","')) + '"}'
        const locale = JSON.parse(data)
        let currentLocale = 'en'

        // if locale and this locale includes in router locales
        if (
          locale.loc &&
          router.locales?.some((lang) => lang === locale.loc.toLowerCase())
        ) {
          currentLocale = locale.loc.toLowerCase()
        } else {
          // another way we get the user's browser languages,
          // matched with router locales,
          // and get first or set the default language as EN
          const matches: string[] = window.navigator.languages.filter((ul) =>
            router.locales?.includes(ul as ILocale)
          )
          currentLocale = matches[0] ?? 'en'
        }
        const lang = localStorage.getItem(langStorageKeyConstant)
        if (!lang) {
          updateLocation(currentLocale as ILocale)
        }
      })
      .catch(() => {
        const lang = localStorage.getItem(langStorageKeyConstant)
        if (!lang) {
          updateLocation('en')
        }
      })
      .finally(() => {
        setIsDefiningLocation(false)
      })
  }, [
    isDefiningLocation,
    router.locale,
    router.locales,
    status,
    updateLocation
  ])

  return (
    <LanguageSettingsContextProvider value={{ switchLanguage }}>
      {(isProcessing || isLanguageChanging) && (
        <div className="fixed z-[300] h-full w-full bg-white">
          <Spinner className="m-auto" isVisible />
        </div>
      )}
      <>{children}</>
    </LanguageSettingsContextProvider>
  )
}

export default UserSettings
